<!--
 * @Descripttion: 
-->
<template>
    <div class="cycycy">
        <v-chip-group :style="{background:$vuetify.theme.dark?'#000000':'#ffffff'}" mandatory active-class="checkchip" class="pl-7 pr-7" >
            <v-chip @click="search(i)" v-for="i in typeArr" :key="i.key">
                {{
                i.txt
                }}
            </v-chip>
        </v-chip-group>

        <div id="home">
            <v-container fluid>
                <mescroll-vue ref="mescroll" class="pt-0 pa-4" :up="mescrollUp" @init="mescrollInit" :style="{background: $vuetify.theme.dark?'none':'#f9f9f9'}">
                    <div class style="height:20px;width:100%"></div>

                    <div class id="dataLists">

                        <div v-if="!typestyle  && vuex_mode == 'photo'" :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}">
                            <div ref="watercontainer">
                                <vue-flex-waterfall v-if="newArr" :col-spacing="spacing" :col="counts">
                                    <template v-for="(item) in newArr">
                                        <video-card-image class="mb-10" :key="item.id" :card="{ maxWidth: 350 }" :video="item" :channel="item" :collectionLists="collectionLists" @refresh="refresh" @getcollectionList="getcollectionList" @showCollect="showCollect" @showMsg="showMsg" @tofeedback="tofeedback"></video-card-image>
                                    </template>
                                </vue-flex-waterfall>
                            </div>
                        </div>

                        <v-row style="background: '#000'" id="dataList" v-else :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}">
                            <v-col v-for="(item, idx) in newArr" :key="item.id" cols="12" sm="6" md="4" lg="3" class="mx-xs-auto" :class="{w20:isw20}">
                                <video-card :style="{width:'100%'}" :card="{ maxWidth: 350 }" :video="item" :channel="item" :collectionLists="collectionLists" @refresh="refresh" @getcollectionList="getcollectionList" @showCollect="showCollect" @showMsg="showMsg" @tofeedback="tofeedback"></video-card>
                            </v-col>
                        </v-row>
                    </div>
                </mescroll-vue>

                <v-overlay :value="feedback">
                    <v-card v-if="feedback" v-click-outside="onClickOutside" :light="$vuetify.theme.dark?false: true" style="position: fixed;top:50%; left: 50%;margin-top: -190px; margin-left: -240px; z-index:10;" text>
                        <img style="position: absolute;top: -40px;right: -40px;cursor: pointer;" src="@/assets/icons/shut.png" alt @click=" feedback = false;" />

                        <v-card-title style="display:flex;">
                            <div>反馈事项</div>
                        </v-card-title>

                        <div class="ml-3 mr-3">
                            <v-divider></v-divider>
                        </div>
                        <v-card-text>
                            <div class style="max-height:240px;overflow-y: auto;">
                                <v-radio-group v-model="feedValue">
                                    <v-radio class="ml-2 interestbar" color="#000" v-for="item in vuex_reportLists" :key="item.id" :label="item.name" :value="item.id"></v-radio>
                                </v-radio-group>
                            </div>
                        </v-card-text>

                        <div class="ml-3 mr-3">
                            <v-divider></v-divider>
                        </div>
                        <div class="ml-3 mr-3 mt-15 pb-15">
                            <v-btn block dark style="color: #fff;" @click="setreport()">确定</v-btn>
                        </div>
                    </v-card>
                </v-overlay>

                <!-- <v-overlay :value="overlay">
                    <v-card light style="position: relative;background: #fff;color: #000;" text>
                        <img
                            style="position: absolute;top: -40px;right: -40px;cursor: pointer;"
                            src="@/assets/icons/shut.png"
                            alt
                            @click="isAddcollection=false, overlay = false;"
                        />

                        <v-card-title style="display:flex;">
                            <div v-if=" !isAddcollection">添加到播放列表</div>
                            <div v-else class>新建收藏夹</div>
                        </v-card-title>

                        <div class="ml-3 mr-3">
                            <v-divider></v-divider>
                        </div>
                        <v-card-text v-if="!isAddcollection">
                            <div class style="max-height:240px;overflow:auto">
                                <div
                                    v-for="item in collectionLists"
                                    v-ripple
                                    class="mt-3 interestbar"
                                    :key="item.id"
                                    @click="setcollection(item.id)"
                                >{{item.name}}</div>
                            </div>
                            <div
                                v-ripple
                                class="mt-3 interestbar"
                                @click=" isAddcollection = true "
                            >
                                <img class="pr-2 iconcuston" src="@/assets/icons/save.png" alt />
                                新建收藏夹
                            </div>
                        </v-card-text>
                        <v-card-text v-else>
                            <div class>
                                <v-switch
                                    v-model=" switch1 "
                                    :label=" switch1 == 1 ? '公开' : '私有'"
                                    color="black"
                                ></v-switch>
                            </div>
                            <div class="interestbar">
                                <v-text-field v-model=" collectTxt " placeholder="收藏夹名称" solo></v-text-field>
                            </div>
                        </v-card-text>
                        <div class="ml-3 mr-3">
                            <v-divider></v-divider>
                        </div>
                        <div class="ml-3 mr-3 mt-15 pb-15">
                            <v-btn
                                v-if="!isAddcollection"
                                block
                                dark
                                style="color: #fff;"
                                @click="overlay = false; isAddcollection = false"
                            >取消</v-btn>
                            <v-btn
                                v-else
                                block
                                dark
                                style="color: #fff;"
                                @click="toaddcollection()"
                            >确定</v-btn>
                        </div>
                    </v-card>
                </v-overlay>-->
                <v-snackbar v-model="isShow" centered :width="'100px'" :timeout="2000">{{ showToast }}</v-snackbar>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueFlexWaterfall from "@/components/vue-flex-waterfall";
import videoCard from "@/components/VideoCard";
import VideoCardImage from "@/components/VideoCardImage";
import MescrollVue from "mescroll.js/mescroll.vue";
import MescrollMixins from "@/assets/MescrollMixins.js";
import "mescroll.js/mescroll.min.css";
export default {
    name: "Home",
    components: {
        videoCard,
        MescrollVue,
        VueFlexWaterfall,
        VideoCardImage,
    },
    mixins: [MescrollMixins],
    data() {
        return {
            feedback: false, //举报弹框
            feedValue: null,

            typestyle: true,
            newArr: [], // 数据列表

            isIntersecting: false,
            loadingtxt: null,
            typeArr: [],

            flag: false,

            currentpage: {
                page: 1,
                limit: 12,
            },
            currentval: null,
            loading: true,
            video: {
                url: "/watch/12",
                thumb: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
                title: "Top western road trips",
                views: "9.6k",
                createdAt: "6 hours ago",
            },
            channel: {
                url: "/channels/12",
                avatar: "https://randomuser.me/api/portraits/men/1.jpg",
            },
            List: [],

            collectionLists: [], //收藏夹
            isAddcollection: false, //是否新增收藏夹
            overlay: false, //收藏弹框
            collectTxt: null,
            switch1: 1, //公开 私有
            routerAlive: false,
            isShow: false, //提示框
            showToast: null,
            currentId: null, //当前弹框id
            mescroll: null, // mescroll实例对象
            mescrollDown: {},
            mescrollUp: {
                callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
                page: {
                    num: 1, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
                    size: 12, // 每页数据的数量
                },
                noMoreSize: 1, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
                toTop: {
                    src: require("@/assets/mescroll-totop.png"), // 回到顶部按钮的图片路径,支持网络图
                },
                empty: {
                    // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
                    warpId: "dataLists", // 父布局的id;
                    icon: require("@/assets/static/mescroll/mescroll-empty.png"), // 图标,支持网络图
                    // icon: "http://www.mescroll.com/img/mescroll-empty.png", // 图标,默认null
                    tip: "没有更多了~", // 提示
                    // btntext: "去逛逛 >", // 按钮,默认""
                    // btnClick() {
                    //     // 点击按钮的回调,默认null
                    //     alert("点击了按钮,具体逻辑自行实现");
                    // },
                },
            },
            dataList: [], // 列表数据
            pdType: 0, // 菜单

            spacing: 25,
            counts: 7,
            clientwidth: 0,
        };
    },
    watch: {
        vuex_indexkey() {
            //首页搜索关键字
            console.log("this.mescroll", this.mescroll);
            // console.log('this.$refs.mescroll',this.$refs.mescroll);
            this.mescroll.resetUpScroll();
            // this.upCallback();
        },
        vuex_mode() {
            this.mescroll.resetUpScroll();
        },
    },
    mounted() {
        this.getCss();
        this.mescroll.resetUpScroll();
        this.initialLabel();
        this.loading = false;
        if (this.collectionLists.length < 1) {
            this.getcollectionList();
        }
        let that = this;
        //  this.$nextTick(function () {
        //     // 仅在整个视图都被渲染之后才会运行的代码
        // })
        (function flexible(window, document) {
            function resetFontSize() {
                if (that.vuex_mode == "photo") {
                    that.gutterWidth();
                }
            }
            // reset root font size on page show or resize
            window.addEventListener("pageshow", resetFontSize);
            window.addEventListener("resize", resetFontSize);
        })(window, document);
    },

    beforeRouteEnter(to, from, next) {
        console.log("from", from?.name != "Detail");
        // 如果没有配置回到顶部按钮或isBounce,则beforeRouteEnter不用写
        next((vm) => {
            if (vm.mescroll) {
                // 恢复到之前设置的isBounce状态
                if (vm.mescroll.lastBounce != null)
                    vm.mescroll.setBounce(vm.mescroll.lastBounce);
                // 滚动到之前列表的位置(注意:路由使用keep-alive才生效)
                if (vm.mescroll.lastScrollTop) {
                    vm.mescroll.scrollTo(vm.mescroll.lastScrollTop);
                    setTimeout(() => {
                        // 需延时,因为setScrollTop内部会触发onScroll,可能会渐显回到顶部按钮
                        vm.mescroll.setTopBtnFadeDuration(0); // 设置回到顶部按钮显示时无渐显动画
                    }, 16);
                }
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        // 如果没有配置回到顶部按钮或isBounce,则beforeRouteLeave不用写
        if (this.mescroll) {
            this.mescroll.lastBounce = this.mescroll.optUp.isBounce; // 记录当前是否禁止ios回弹
            this.mescroll.setBounce(true); // 允许bounce
            this.mescroll.lastScrollTop = this.mescroll.getScrollTop(); // 记录当前滚动条的位置
            this.mescroll.hideTopBtn(0); // 隐藏回到顶部按钮,无渐隐动画
        }
        next();
    },
    methods: {
        gutterWidth: async function () {
            await this.$nextTick();
            let ele = this.$refs.watercontainer;
            // var ele = document.getElementById("watercontainer");
            let width = window.getComputedStyle(ele).width;
            console.log('width', width);
            let widthnum = width.split("px")[0];
            this.clientwidth = widthnum;
            let counts = 7;
            if (widthnum <= 700) {
                counts = 1;
                console.log("counts1", widthnum, counts);
            } else if (widthnum <= 1100 && widthnum > 700) {
                counts = 4;
                console.log("counts2", widthnum, counts);
            } else if (widthnum <= 1460 && widthnum > 1100) {
                console.log("counts3", widthnum, counts);
                counts = 6;
            } else if (widthnum <= 1904 && widthnum > 1400) {
                counts = 7;
                console.log("counts4", widthnum, counts);
            }
            this.counts = counts;

            let spacing = (width.split("px")[0] - 200 * counts) / counts;
            this.spacing = spacing > 0 ? spacing : 0;
        },
        setreport() {
            let url = "/api/Operation/report";
            this.$ajax
                .post(url, {
                    sid: this.feedback,
                    rid: this.feedValue,
                    type: this.getType,
                })
                .then((res) => {
                    this.showMsg(res.msg);
                    this.feedback = null;
                });
        },
        tofeedback(resourcesId) {
            this.feedback = resourcesId;
        },
        onClickOutside() {
            console.log("oustside");
            if (this.feedback) {
                this.feedback = null;
            }
        },
        getCss() {
            this.typestyle = false;
            // let url = "/api/Index/getcss";
            // this.$ajax.post(url, {}).then((res) => {
            //     if (res.picture == 2) {
            //         this.typestyle = false;
            //     } else {
            //         this.typestyle = true;
            //     }
            //     console.log("res", res);
            // });
        },
        showMsg(msg) {
            this.showToast = msg;
            this.isShow = true;
        },
        // mescroll组件初始化的回调,可获取到mescroll对象
        mescrollInit(mescroll) {
            this.mescroll = mescroll;
            console.log("this.mescroll", this.mescroll);
        },

        setcollection(collectionId, resourcesId = this.video.id) {
            let url = "/api/Operation/favorite";
            if (this.currentId) {
                resourcesId = this.currentId;
            }
            this.$ajax
                .post(url, {
                    fid: collectionId,
                    rid: resourcesId,
                    type: this.getType,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                });
        },
        showCollect(videoId) {
            this.currentId = videoId;
            this.overlay = true;
        },
        getcollectionList() {
            let url = "/api/Operation/getcollection";
            this.$ajax.post(url, {}).then((res) => {
                this.collectionLists = res.folder;
            });
        },
        toaddcollection() {
            let url = "/api/Operation/collection";
            this.$ajax
                .post(url, {
                    name: this.collectTxt,
                    privacy: this.switch1,
                })
                .then((res) => {
                    this.isAddcollection = false;
                    if (res.code == "1") {
                        this.getcollectionList();
                        this.showToast = res.msg;
                        this.isShow = true;
                    }
                });
        },
        refresh() {
            this.mescroll.resetUpScroll();
        },
        // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
        upCallback(page = { num: 1, size: 12 }) {
            // 联网加载数据
            console.log("loading");
            this.loading = true;
            let url = "/api/Index/getvideo";
            if (this.vuex_mode == "photo") {
                url = "/api/Index/getpicture";
            } else if (this.vuex_mode == "article" || this.vuex_mode == "art") {
                url = "/api/Index/getarticle";
            } else {
                url = "/api/Index/getvideo";
            }

            this.$ajax
                .post(url, {
                    page: page.num,
                    limit: 12,
                    where:
                        this.vuex_indexkey == "全部" ? "" : this.vuex_indexkey,
                    param:1
                })
                .then((res) => {
                    let curPageData = res.data;
                    if (page.num === 1) this.newArr = [];
                    // 添加列表数据
                    this.newArr = this.newArr.concat(curPageData);
                    // 数据渲染成功后,隐藏下拉刷新的状态
                    this.$nextTick(() => {
                        this.mescroll.endSuccess(curPageData.length);
                    });
                    this.loading = false;
                })
                .catch((e) => {
                    console.log("error", e);
                    this.mescroll.endErr();
                });
        },

        // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
        pageInput(e) {
            console.log("this.e", e);
            this.currentpage.page = e;
            this.mescroll.resetUpScroll();
        },
        search(parme) {
            this.currentpage.page = 1;
            this.currentval = parme.key;
            this.vuex("vuex_indexkey", parme.txt);
            this.mescroll.resetUpScroll();
        },

        initialLabel() {
            //获取标签
            let url = "/api/Index/getlabel";
            this.$ajax.post(url, {}).then((res) => {
                if (res.code == 1) {
                    let arr = res.data;
                    let typeArr = [
                        {
                            txt: "全部",
                            key: null,
                        },
                    ];

                    for (let i in arr) {
                        typeArr.push({
                            txt: arr[i].lname,
                            key: arr[i].id,
                        });
                    }

                    typeArr.sort(function (a, b) {
                        return a.key - b.key;
                    });
                    this.typeArr = typeArr;
                }
            });
        }, //布局
        scroll(scrollData) {
            // console.log(scrollData)
        },

        switchCol(col) {
            this.col = col; // console.log(this.col)
        },
    },
    computed: {
        ...mapGetters(["getType"]),
        isw20() {
            if (this.$vuetify.breakpoint.name == 'xl' && this.vuex_miniVariant) {
                return true
            } else {
                return false
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.w20 {
    max-width: 20%;
}
.checkchip {
    background: #848484;
    color: #fafafa !important;
}
.card {
    background: #f9f9f9 !important;
}
.type_select {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0 20px;
    border-radius: 20px;
    background: #f0f0f0;
    border: 1px solid #d4d4d4;
    margin: 20px;
}
.inner-container::-webkit-scrollbar {
    display: none;
}

.mescroll {
    position: absolute;
    top: 48px;
    bottom: 0;
    left: 0;
    height: auto;
}
</style>
