<template>
    <v-card class="mx-auto content-bg card" :max-width="200" flat tile router>
        <div
            style="width: 100%; height: auto; padding: 0;cursor: pointer;"
            v-ripple="true"
            @click="linkDetail(video)"
        >
            <img
                style="width:100%"
                :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                class="rounded-xl contain"
                v-if="video.imgid"
                :src="vuex_baseUrl + video.imgid"
            />
            <img
                style="width:100%;height: auto;"
                :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                class="rounded-xl contain"
                v-else-if="video.image"
                :src="vuex_baseUrl + video.image"
            />
        </div>
        <v-row no-gutters :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}">
            <v-col cols="2" v-if="card.type != 'noAvatar'">
                <v-list-item class="pt-3 pl-0" router :to="channel.url">
                    <v-list-item-avatar color="grey darken-3">
                        <v-img
                            class="elevation-6 cursor"
                            @click="toHome(video.uid)"
                            :src="vuex_baseUrl + channel.headimg"
                            :lazy-src="vuex_base64Avatar"
                        ></v-img>
                    </v-list-item-avatar>
                </v-list-item>
            </v-col>
            <v-col cols="9">
                <v-card-title
                    class="pt-3 pl-2 pr-2 subtitle-1 font-weight-bold cursor"   @click="linkDetail(video)"
                >{{ video.pname || video.name}}</v-card-title>
                <v-card-subtitle class="pb-0 pl-2 pr-2 cursor"  @click="toHome(video.uid)">
                    {{ channel.nickname.slice(0,8) }}
                    <v-icon>mdi-circle-small</v-icon>
                    {{dateFormatter(video.time) }}
                </v-card-subtitle>
            </v-col>
            <v-col cols="1">
                <!-- v-if="menu = video.id" -->
                <v-menu bottom left :close-on-click="true" :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mt-4" icon v-on="on" v-bind="attrs" @click="menu = video.id">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="setuninterested(video.id)">
                            <v-list-item-title class="d-flex align-center">
                                <img
                                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                    width="16px"
                                    height="16px"
                                    src="@/assets/icons/no_2.png"
                                />
                                <div class="ml-1">不感兴趣</div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setcollection(video.id)">
                            <v-list-item-title class="d-flex align-center">
                                <img
                                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                    width="16px"
                                    height="16px"
                                    src="@/assets/icons/save.png"
                                />
                                <div class="ml-1">收藏</div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="tofeedback(video.id)">
                            <v-list-item-title class="d-flex align-center">
                                <img
                                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                    width="16px"
                                    height="16px"
                                    src="@/assets/icons/save.png"
                                />
                                <div class="ml-1">举报</div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="menu = false">
                            <v-list-item-title class="d-flex align-center">
                                <img
                                    :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}"
                                    width="16px"
                                    height="16px"
                                    src="@/assets/icons/calc.png"
                                />
                                <div class="ml-1">取消</div>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-overlay :value="feedback">
            <v-card
                v-click-outside="onClickOutside"
                light
                style="position: relative;background: #fff;color: #000;"
                text
            >
                <img
                    style="position: absolute;top: -40px;right: -40px;cursor: pointer;"
                    src="@/assets/icons/shut.png"
                    alt
                    @click=" feedback = false;"
                />

                <v-card-title style="display:flex;">
                    <div>反馈事项</div>
                </v-card-title>

                <div class="ml-3 mr-3">
                    <v-divider></v-divider>
                </div>
                <v-card-text>
                    <div class style="max-height:240px;overflow-y: auto;">
                        <v-radio-group v-model="feedValue">
                            <v-radio
                                class="interestbar ml-2"
                                color="#000"
                                v-for="item in vuex_reportLists"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-card-text>

                <div class="ml-3 mr-3">
                    <v-divider></v-divider>
                </div>
                <div class="ml-3 mr-3 mt-15 pb-15">
                    <v-btn block dark style="color: #fff;" @click="setreport()">确定</v-btn>
                </div>
            </v-card>
        </v-overlay>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("zh-cn");
export default {
    props: {
        video: {
            type: Object,
            required: true,
        },
        channel: {
            type: Object,
            required: true,
        },
        card: Object,
        collectionLists: [], //收藏文件夹列表
    },
    data: () => ({
        show: false,
        menu: false,
        isAddcollection: false, //是否新增收藏夹
        overlay: false, //收藏弹框
        collectTxt: null,
        switch1: 1, //公开 私有
        routerAlive: false,
        isShow: false, //提示框
        showToast: null,
        feedback: false, //反馈弹窗
        feedValue: null,
    }),
    computed: {
        ...mapGetters(["getType"]),
    },
    methods: {
        toHome(id) {
            this.$router.push("/channels/" + id);
        },
        onClickOutside() {
            console.log("oustside");
            if (this.feedback) {
                this.feedback = null;
            }
        },
        tofeedback(resourcesId) {
            this.$emit("tofeedback", resourcesId);
        },
        setreport() {
            let url = "/api/Operation/report";
            this.$ajax
                .post(url, {
                    sid: this.feedback,
                    rid: this.feedValue,
                    type: this.getType,
                })
                .then((res) => {
                    this.$emit("showMsg", res.msg);
                    this.$emit("refresh");
                });
        },
        dateFormatter(date) {
            return moment(date).fromNow();
        },
        showCollect(videId = this.video.id) {
            console.log(this.menu);
            this.menu = null;
            this.$emit("showCollect", videId);
        },
        linkDetail(parme) {
            this.$router.push(`/detail/${parme.id}`);
        },
        setuninterested(resourcesId) {
            let url = "/api/Operation/uninterested";
            let that = this;
            this.$ajax
                .post(url, {
                    rid: resourcesId,
                    type: this.getType,
                })
                .then((res) => {
                    this.$emit("showMsg", res.msg);

                    that.$emit("refresh");
                });
        },
        setcollection(resourcesId) {
            let url = "/api/Operation/favorite";

            this.$ajax
                .post(url, {
                    rid: resourcesId,
                    type: this.getType,
                })
                .then((res) => {
                    this.$emit("showMsg", res.msg);
                });
        },
        getcollectionList() {
            this.emit("getcollectionList");
        },
    },
};
</script>

<style>
.interestbar {
    display: flex;
    align-items: center;
    /* min-width: 500px; */
    cursor: pointer;
}
</style>
